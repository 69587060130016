<template>
  <v-container fluid ref="proj_cards" class="image-background">
    <app-bar :site_sections="site_sections" :contact="contact" positive :portfolio="[]"/>
    <v-row
      no-gutters
      justify="center"
    >
      <v-col cols="12" class="d-flex justify-center">
        <p class="display-1 secondary--text font-weight-bold mt-14">{{ $t('our_work.title') }}</p>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      class="mt-2 mb-12"
    >
      <p class="secondary--text font-weight-medium">
        {{ $t('our_work.content') }}
      </p>
    </v-row>
    <v-row justify="center" class="px-md-14 px-5">
      <template v-for="(item, i) in items">
        <v-col :key="`project_${i}`" :cols="$vuetify.breakpoint.mobile ? 12 : 6" class="pa-5">
          <v-card
            :class="{ 'animatedproj--show': item.isIntersecting, 'animatedproj--hide': !item.isIntersecting }"
            class="animatedproj"
            :style="transform(i, item.isIntersecting)"
            color="secondary"
            :id="`${i}`"
            v-intersect="onIntersect"
            :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '645' : '770'"
          >
            <v-row no-gutters :class="item.color === '#62F4A4' ? '#333333' : 'white--text'">
              <v-col
                cols="12"
                class="position-absolute pa-4 pa-md-8 pb-0"
                :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 160px;' : 'height: 210px;'"
              >
                <div class="d-inline-flex justify-space-between w-100">
                  <span>
                    <p v-if="item.country" class="font-weight-medium text-lg-h6 text-sm-body-1 text-subtitle-2">
                      {{ item.country }}
                    </p>
                  </span>
                  <span class="d-inline-flex">
                    <template v-if="item.type">
                      <p class="font-weight-light text-lg-h6 text-sm-body-1 text-subtitle-2 mr-2">{{ $t(`project_types.${item.type}`) }}</p>
                      <v-icon :color="item.color === '#62F4A4' ? '#333333' : 'white'">
                        {{ item.type === 'dev' ? 'mdi-keyboard' : 'mdi-wrench' }}
                      </v-icon>
                    </template>
                  </span>
                </div>
                <p class="text-lg-h4 text-sm-h5 text-h6 font-weight-medium pt-lg-6 pt-4">
                  {{ $t(`projects.${item.key}.title`) }}
                </p>
              </v-col>
              <v-col
                cols="12"
                class="px-4 px-md-8 pt-lg-5 pt-0"
                :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 130px;' : 'height: 205px;'"
              >
                <p class="font-weight-light text-sm-body-1 text-subtitle-2">
                  {{ $t(`projects.${item.key}.text`) }}
                </p>
              </v-col>
              <v-col cols="12" class="px-4 px-lg-8 py-lg-4 py-2 d-inline-flex justify-space-between align-end" style="height: 75px;">
                <p class="font-weight-medium text-lg-h6 text-sm-body-1 text-subtitle-2">
                  {{ item.client }}
                </p>
                <p class="font-weight-light text-lg-h6 text-sm-body-1 text-subtitle-2">
                  {{ getReadableYear(item) }}
                </p>
              </v-col>
              <v-col cols="12" style="height: 300px;">
                <v-hover v-slot="{ hover }">
                  <v-img
                    @mousemove.stop="onMouseMove"
                    @mouseleave.stop="hoverPosition = '50% 0%'"
                    v-if="item.imgSrc"
                    :src="item.imgSrc"
                    :class="!$vuetify.breakpoint.mobile ? { 'on-hover': hover } : {}"
                    :style="`${item.URL ? 'cursor: pointer' : ''}`"
                    height="265"
                    class="mx-4 mb-4 white--text align-end project-img"
                    @click="clickImage(item)"
                    :position="hover && hoverPosition || '50% 0%'"
                  >
                    <!-- :position="item.position ? item.position : item.imgSrc ? '30% 0%' : '30% 55%'" -->
                    <!-- :gradient="(hover || $vuetify.breakpoint.mobile) && item.URL ? `to top, rgba(10,10,10,1) 0%, ${$vuetify.breakpoint.mobile ? '14%' : '17%'}, rgba(10,10,10,0) 25%` : ''" -->
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <!-- <div class="gradient"></div> -->
                      <!-- v-if="hover || $vuetify.breakpoint.mobile" -->
                    <!-- <div
                      class="gradient d-flex align-center justify-end"
                      :class="`${item.URL ? '' : 'd-none'}` +
                      `${hover || $vuetify.breakpoint.mobile ? 'gradient--show' : ''}` +
                      `${item.URL ? '' : 'gradient--disabled'} ` +
                      `${$vuetify.breakpoint.mobile && item.URL ? 'gradient--mobile' : ''}`"
                      >
                      <v-tooltip top right v-if="item.URL">
                        <template #activator="{on, attrs}">
                          <p
                            v-bind="attrs"
                            v-on="on"
                            class="font-weight-medium text-right text-body-1 mr-4"
                          >
                            Visitar
                            <v-icon color="white">mdi-link-variant</v-icon>
                          </p>
                        </template>
                        <span>{{item.URL}}</span>
                      </v-tooltip>
                    </div> -->
                  </v-img>
                  <div v-else class="project-img__placeholder w-100 h-100 bg-primary d-flex align-center justify-center">
                    <v-img  
                      :src="require('@/assets/img/1x_PNG/v_principal_verde.png')" 
                      contain
                      :class="!$vuetify.breakpoint.mobile ? { 'on-hover': hover } : {}"
                      :style="`${item.URL ? 'cursor: pointer' : ''}`"
                      height="100"
                      class="mx-4 mb-4 project-img"
                    />
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AppBar from '../components/AppBar';

export default {
  name: 'Projects',
  components: {
    AppBar,
  },
  data() {
    return {
      // eslint-disable-next-line
      site_sections: [ // To be used in app bar
      ],
      projects: [
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/btg.png'),
          key: 'btg',
          client: 'BTG Pactual',
          type: 'dev',
          URL: 'http://ml.cl/',
          startYear: 2022,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/sentraland.png'),
          key: 'ams',
          client: 'AMS',
          type: 'dev',
          startYear: 2022,
          endYear: 2022,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/teselagen.png'),
          key: 'teselagen',
          client: 'TeselaGen',
          type: 'dev',
          URL: 'https://teselagen.com/design-module/',
          startYear: 2022,
          endYear: 2023,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/salah.png'),
          key: 'salah',
          client: 'Sebastián Salah',
          type: 'dev',
          startYear: 2021,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/venew.png'),
          key: 'venew',
          client: 'Venew',
          position: '30% 0%',
          type: 'dev',
          URL: 'https://venew.co/',
          startYear: 2020,
          endYear: 2021,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/consilium-eeuu.png'),
          key: 'consilium',
          country: '🇺🇸 EEUU',
          client: 'ConsiliumBots',
          position: '30% 2%',
          type: 'dev',
          URL: 'https://explore.newhavenmagnetschools.com/',
          startYear: 2020,
          endYear: 2021,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/consilium-ecuador.png'),
          key: 'consilium:ecuador',
          country: '🇪🇨 Ecuador',
          client: 'ConsiliumBots',
          position: '30% 0%',
          type: 'dev',
          URL: 'https://inscripcionmanta.educacion.gob.ec/',
          startYear: 2020,
          endYear: 2021,
        },
        {
          key: 'consilium:brasil',
          country: '🇧🇷 Brasil',
          client: 'ConsiliumBots',
          type: 'dev',
          startYear: 2020,
          endYear: 2020,
        },
        {
          key: 'consilium:peru',
          country: '🇵🇪 Perú',
          text: 'consilium:peru:text',
          client: 'ConsiliumBots',
          type: 'dev',
          startYear: 2019,
          endYear: 2019,
        },
        {
          key: 'consilium:preschool',
          client: 'ConsiliumBots',
          type: 'dev',
          startYear: 2019,
          endYear: 2019,
        },
        {
          key: 'consilium:dominican',
          country: '🇩🇴 Rep. Dominicana',
          client: 'ConsiliumBots',
          type: 'dev',
          URL: 'https://www.egresa.do/',
          startYear: 2019,
          endYear: 2019,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/seer.png'),
          key: 'seer',
          client: 'Seer',
          position: '30% 0%',
          type: 'dev',
          URL: 'https://www.helloseer.com/',
          startYear: 2020,
          endYear: 2021,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/ecgo.jpg'),
          key: 'solunova',
          client: 'SoluNova',
          type: 'upkeep',
          URL: 'https://ecgo.cl/',
          startYear: 2020,
          endYear: 2020,
        },
        {
          key: 'vixonic',
          client: 'Vixonic',
          type: 'upkeep',
          startYear: 2019,
          endYear: 2020,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/southernrock-gdas32.jpg'),
          key: 'southernrock',
          client: 'SouthernRock Geophysics',
          type: 'dev',
          startYear: 2019,
          endYear: 2019,
        },
        {
          key: 'manungo',
          client: 'Mañungo',
          type: 'upkeep',
          startYear: 2018,
          endYear: 2018,
        },
        {
          key: 'acepta',
          client: 'Acepta',
          type: 'dev',
          startYear: 2018,
          endYear: 2019,
        },
        {
          key: 'mandomedio',
          client: 'Mandomedio',
          type: 'dev',
          startYear: 2017, 
          endYear: 2017,
        },
        {
          key: 'forcast',
          client: 'Forcast',
          type: 'dev',
          startYear: 2018,
          endYear: 2019,
        },
        {
          key: 'asicom',
          client: 'Asicom',
          type: 'dev',
          startYear: 2019,
          endYear: 2019,
        },
        {
          key: 'asicom:itau',
          client: 'Asicom',
          type: 'dev',
          startYear: 2019,
          endYear: 2020,
        },
        {
          key: 'asicom:nuevocapital',
          client: 'Asicom',
          type: 'dev',
          startYear: 2019,
          endYear: 2020,
        },
        {
          key: 'nalkip',
          client: 'Nalkip',
          type: 'upkeep',
          startYear: 2019,
          endYear: 2019,
        },
        // {
        //   imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
        //   title: 'Centralized Choice and Assignment Systems',
        //   text: '?',
        //   client: '?',
        //   type: 'dev',
        //   year: '?',
        //   URL: 'https://www.ccas-project.org/',
        // },
      ].map(i => ({ ...i, isIntersecting: false })),
      hoverPosition: '0% 0%',
    };
  },
  computed: {
    contact() {
      return {
        title: this.$t('app_bar.contact_us'),
        id: 'contact',
      };
    },
    items() {
      return this.projects.slice().sort((i1, i2) => {
        // Current projects first
        if (!i1.endYear && i2.endYear) return -1;
        if (i1.endYear && !i2.endYear) return 1;
        if (!i1.endYear && !i2.endYear) return i1.startYear > i2.startYear;
        // Finished projects: Last finished first
        return i2.endYear - i1.endYear;
      });
    },
  },
  methods: {
    onIntersect(entries) {
      this.items[entries[0].target.id].isIntersecting = entries[0].isIntersecting;
    },
    transform(i, visible) {
      let translate = 0;
      let scale = 1;
      if (!visible) {
        translate = i % 2 === 0 ? -10 : 10;
        scale = 1;
      }
      return `transform: translateX(${translate}%) scale(${scale})`;
    },
    clickImage(item) {
      if (!item.URL) { return; }
      window.open(item.URL || '/');
    },
    getReadableYear({ startYear, endYear }) {
      return startYear === endYear ? startYear
        : `${startYear} - ${endYear || this.$t('our_work.now')}`;
    },
    onMouseMove(e) {
      this.hoverPosition = `${e.layerX / e.target.clientWidth * 100}% ${e.layerY / e.target.clientHeight * 100}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.position-absolute {
  position: relative;
}
.image-background {
  background-image: url('https://wallpapercave.com/wp/wp6446359.png');
  background-repeat: repeat;
  background-position: center;
}
.animatedproj {
  transition: transform 0.8s ease-in-out, opacity 0.7s ease-in-out;
  &--show {
    opacity: 1;
  }
  &--hide {
    opacity: 0;
  }
}
.project-img {
  overflow: hidden;

  ::v-deep .v-image__image {
    transition: background-position .2s ease-out;
  }
  &.on-hover {
    ::v-deep .v-image__image {
      transition: none;
      // transform: scale(1.03);
    }
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgb(10, 10, 10) 0%, calc(4 * 17%), rgba(10, 10, 10, 0) 100%);
    &--mobile {
      background: linear-gradient(to top, rgb(10, 10, 10) 0%, calc(4 * 14%), rgba(10, 10, 10, 0) 100%);
    }
    transition: opacity .3s ease-in-out;
    opacity: 0;
    &--show, &--mobile {
      opacity: 1;
    }
    &--disabled {
      display: none;
      pointer-events: none;
    }
  }
  &__placeholder {
    background: linear-gradient(-5deg, rgba(white, 20%) 20%, rgba($djablue, 80%) 80%);
  }
}

</style>
